import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto py-16 px-4 md:px-8 lg:px-16 text-white bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>
      <p className="text-sm text-blue-400 mb-8 text-center">Last updated: October 17, 2024</p>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">1. Introduction</h2>
          <p>
            Welcome to SwitchUp's Privacy Policy. This Policy describes how SwitchUp ("we", "our", or "us") collects, uses, and shares your personal information when you use our services. We are committed to protecting your privacy and ensuring you have a positive experience on our platform.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">2. Information We Collect</h2>
          <h3 className="text-sky-500 text-xl font-semibold mb-2">2.1 Information You Provide</h3>
          <ul className="list-disc list-inside">
            <li>Account information (name, email, password)</li>
            <li>Profile information (job title, company name)</li>
            <li>Payment and billing information</li>
            <li>Documents and files you upload for analysis</li>
            <li>Custom tags, annotations, and metadata you add to documents</li>
            <li>Communications with our support team</li>
          </ul>
          <h3 className="text-sky-500 text-xl font-semibold mt-4 mb-2">2.2 Automatically Collected Information</h3>
          <ul className="list-disc list-inside">
            <li>Usage data (features used, actions taken, analysis results)</li>
            <li>Log data (IP address, browser type, operating system)</li>
            <li>Device information (device type, settings)</li>
            <li>Location information (derived from IP address)</li>
          </ul>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
          <p>We use the collected information for various purposes, including:</p>
          <ul className="list-disc list-inside">
            <li>Communicating with you about your account and our services</li>
            <li>Sending you important updates and security alerts</li>
            <li>Analyzing usage patterns to improve our platform</li>
            <li>Detecting and preventing fraudulent or unauthorized use</li>
            <li>Complying with legal obligations</li>
          </ul>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">4. Data Sharing and Disclosure</h2>
          <p>We may share your information in the following circumstances:</p>
          <ul className="list-disc list-inside">
            <li>With your consent or at your direction</li>
            <li>With service providers who assist in our operations (e.g., cloud storage, payment processing)</li>
            <li>In response to legal requests or to comply with applicable laws</li>
            <li>To protect our rights, privacy, safety, or property</li>
            <li>In connection with a merger, acquisition, or sale of assets</li>
          </ul>
          <p>We do not sell your personal information to third parties.</p>
        </section>

        <section>
          <h2 className="text-sky-300text-2xl font-semibold mb-4">5. Data Security</h2>
          <p>We implement robust security measures to protect your data, including:</p>
          <ul className="list-disc list-inside">
            <li>Encryption of data in transit and at rest</li>
            <li>Regular security audits and penetration testing</li>
            <li>Access controls and authentication mechanisms</li>
            <li>Employee training on data protection and privacy</li>
          </ul>
          <p>However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">6. Your Rights and Choices</h2>
          <p>Depending on your location, you may have certain rights regarding your personal information:</p>
          <ul className="list-disc list-inside">
            <li>Rectify inaccurate or incomplete information</li>
            <li>Request deletion of your personal information</li>
            <li>Restrict or object to certain processing activities</li>
            <li>Data portability</li>
            <li>Withdraw consent (where applicable)</li>
          </ul>
          <p>To exercise these rights, please contact us using the information provided in the "Contact Us" section.</p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">7. Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
          <p>Email: <a href="mailto:hello@switchupev.in" className="text-sky-500 hover:underline">hello@switchupev.in</a></p>
          <p>Address: Hustlehub Tech Park Building, PWD Quarters, HSR Layout, Bengaluru, Karnataka, 560102</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;