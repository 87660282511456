import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto py-16 px-4 md:px-8 lg:px-16 text-white bg-gray-900">
      <h1 className="text-4xl font-bold mb-16 text-center">Terms of Use</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">1. WHO WE ARE AND HOW TO CONTACT US</h2>
          <p>
          <a href="/" className="text-sky-500 hover:underline">switchupev.in</a> is a website (Website) operated at office at Hustlehub Tech Park Building, PWD Quarters, HSR Layout, Bengaluru, Karnataka, 560102.
          </p>
          <br></br>
          <p>
            To contact us, please use the contact us button on the navigation bar on top or email <a href="mailto:hello@switchupev.in" className="text-sky-500 hover:underline">hello@switchupev.in</a> or call +91 966 332 50369.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">2. AGREEMENT TO TERMS</h2>
          <p>
            By using our Website, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our Website.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">3. SERVICE OVERVIEW</h2>
          <p>
            SwitchUpev offers services related to helping customers in Buy/Resale of the vehicle by charging small premium amount. We maintain the right to alter, suspend, or terminate any aspect of the service at any time, with or without prior notice.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">4. ACCOUNT RESPONSIBILITIES</h2>
          <p>
            Certain features of our service may require the creation of an account. You are responsible for safeguarding your account details and for all actions performed under your account.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">5. USER RESPONSIBILITIES</h2>
          <p>
            You agree to use the service only for lawful purposes and in a manner that does not disrupt, damage, or impair the service. Prohibited actions include, but are not limited to:
          </p>
          <ul className="list-disc list-inside">
            <li>Breaking any applicable laws or regulations</li>
            <li>Violating the rights of others</li>
            <li>Attempting unauthorized access to the service or related systems</li>
            <li>Disrupting other users' access to or enjoyment of the service</li>
          </ul>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">6. INTELLECTUAL PROPERTY RIGHTS</h2>
          <p>
            All content, features, and functionalities of the service are the exclusive property of SwitchUpev and are protected by international copyright, trademark, and other intellectual property laws.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">7. LIMITATION OF LIABILITY</h2>
          <p>
            In no event will we, our affiliates or their licensors, service providers, employees, agents, officers or directors be liable for any indirect, incidental, special, consequential or punitive damages arising out of or in connection with your use of the service.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">8. AMENDMENTS TO TERMS</h2>
          <p>
            We reserve the right to update these Terms of Service at any time. Significant changes will be communicated by updating the Terms on this page. Continued use of the service following such changes constitutes your acceptance of the revised Terms.
          </p>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">9. CANCELLATION POLICY</h2>
          <p>
            As SwitchUpev provides services, our cancellation policy is as follows:
          </p>
          <ul className="list-disc list-inside">
            <li>No refunds will be provided for use of the any service.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-sky-300 text-2xl font-semibold mb-4">10. CONTACT INFORMATION</h2>
          <p>
            If you have any questions regarding these Terms of Service, please reach out to us at:  Email: <a href="mailto:hello@switchupev.in" className="text-sky-500 hover:underline">hello@switchupev.in</a>
          </p>
        <br></br>
          <p>
          Hustlehub Tech Park Building, PWD Quarters, HSR Layout, Bengaluru, Karnataka, 560102
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;