import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import backgroundImage from './assets/images/background.jpg';
import logo from './assets/images/logo.png';
import founder1 from './assets/images/founder1.jpg';
import founder2 from './assets/images/founder2.jpg';
import './Loading.css';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="electric">
        <div className="bolt"></div>
        <div className="bolt"></div>
        <div className="bolt"></div>
      </div>
      <h1 className="loading-text">Loading...</h1>
    </div>
  );
};

const MainApp = () => {
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {/* Navbar */}
      <div className='Nav'>
        <nav className="fixed w-full top-0 bg-white bg-opacity-25 backdrop-blur-lg z-50 text-white px-4 md:px-8 py-4 flex justify-between items-center">
          <div className="text-3xl font-extrabold tracking-wider">
            <img src={logo} alt="Logo" className="mx-4 h-7 w-auto" />
          </div>
          <ul className="hidden md:flex space-x-4 md:space-x-10">
            {['Home', 'Products', 'Contact', 'About Us'].map((item, idx) => (
              <li key={idx}>
                <a
                  href={`#${item.toLowerCase().replace(/\s/g, '')}`}
                  className="text-lg md:text-xl transition duration-300 ease-in-out hover:text-red-500 hover:scale-110"
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
          <button onClick={toggleMenu} className="md:hidden text-white">
            Menu
          </button>
        </nav>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-black bg-opacity-75 z-50 shadow-lg md:hidden">
          <ul className="flex flex-col space-y-4 px-4 py-6">
            {['Home', 'Products', 'Contact', 'About Us'].map((item, idx) => (
              <li key={idx}>
                <a
                  href={`#${item.toLowerCase().replace(/\s/g, '')}`}
                  className="text-white text-xl transition duration-300 ease-in-out hover:text-red-500"
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Home Section */}
      <section
        id="home"
        className="min-h-screen bg-fixed bg-center bg-cover relative"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-40 z-0"></div>
        <div className="flex items-start justify-start h-full pt-24 relative z-10 text-left px-4 md:px-8">
          <h1 className="max-w-[70%] text-lg md:text-xl lg:text-3xl font-extrabold text-white drop-shadow-lg animate-fade-in transition duration-500 opacity-90"
              style={{
                 textShadow: '0 0 2px rgba(135, 206, 250, 0.4), 0 0 5px rgba(135, 206, 250, 0.2), 0 0 10px rgba(135, 206, 250, 0.1)' 
              }}>
            <br /><br /><br /><br /><br />
            Bringing the latest<br /> superlative ownership <br /> experience programs for<br />
            <span className="text-sky-500 text-xl md:text-2xl lg:text-4xl"
                  style={{
                   textShadow: '0 0 2px rgba(135, 206, 250, 0.4), 0 0 5px rgba(135, 206, 250, 0.2), 0 0 10px rgba(135, 206, 250, 0.1)'
                  }}>
              EV owners and intenders
            </span>
          </h1>
        </div>
      </section>

      {/* Products Section */}
<section id="products" className="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white py-16">
  <div className="container mx-auto px-4 md:px-8">
    <h2 className="text-4xl md:text-5xl font-bold text-center mb-4 text-gray-100">Our Superlative Ownership Products</h2>
    <p className="text-center text-lg md:text-xl text-gray-400 mb-12">
      Price starting from <span className="text-sky-500 text-2xl font-bold">Rs. 1,999</span>
    </p>

    {/* Switchup Club */}
    <div className="bg-gray-800 p-6 md:p-8 mb-12 rounded-lg shadow-lg flex flex-col md:flex-row items-center gap-6">
      <i className="fas fa-bolt text-red-500 text-5xl md:text-6xl"></i>
      <div className="flex-grow">
        <h3 className="text-3xl md:text-3xl font-semibold mb-4">SwitchUp Club</h3>
        <p className="text-gray-400">
          The exclusive membership program for EV owners which acts as a one-stop shop for managing their EVs. From emergency charging to maintenance, insurance, and EV ecosystem services, this program offers everything an EV owner could need.
        </p>
      </div>
      <span className="text-red-500 text-3xl animate-bounce"></span>
    </div>

    {/* SwitchUp Select */}
    <div className="bg-gray-800 p-6 md:p-8 mb-12 rounded-lg shadow-lg flex flex-col md:flex-row items-center gap-6">
      <i className="fas fa-sync-alt text-blue-500 text-5xl md:text-6xl"></i>
      <div className="flex-grow">
        <h3 className="text-3xl md:text-3xl font-semibold mb-4">SwitchUp Select</h3>
        <p className="text-gray-400">
          An EV companion program to help owners transition seamlessly from one EV to another. Get real-time battery insights, guaranteed Trade-In value, and experience a smooth upgrade to the next EV.
        </p>
      </div>
      <span className="text-red-500 text-3xl animate-bounce"></span>
    </div>

    {/* Switchup Crest */}
    <div className="bg-gray-800 p-6 md:p-8 mb-12 rounded-lg shadow-lg flex flex-col md:flex-row items-center gap-6">
      <i className="fas fa-car text-green-500 text-5xl md:text-6xl"></i>
      <div className="flex-grow">
        <h3 className="text-3xl md:text-3xl font-semibold mb-4">SwitchUp Crest</h3>
        <p className="text-gray-400">
          The ultimate EV ownership program offering benefits from the Club product, plus lower EMIs, and an easier path to owning the latest and best EVs. Always drive the best with SwitchUp Crest.
        </p>
      </div>
      <span className="text-red-500 text-3xl animate-bounce"></span>
    </div>
  </div>
</section>

      {/* Contact Section */}
      <section id="contact" className="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-800 text-white py-16">
        <div className="container mx-auto px-4 md:px-8">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-5 text-white-400">Get in Touch</h2>
          <form className="max-w-lg mx-auto space-y-8 bg-black-900 p-6 md:p-10 rounded-lg shadow-xl border border-gray-900">
            {['Name', 'Phone No', 'Email', 'Message'].map((field, idx) => (
              <div key={idx} className="relative">
                <label className="block mb-2 text-lg font-semibold text-gray-300">{field}</label>
                {field === 'Message' ? (
                  <textarea
                    className="w-full p-3 pl-12 bg-gray-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-sky-500 transition duration-300 ease-in-out"
                    rows="4"
                    placeholder={`Enter your ${field.toLowerCase()}`}
                  ></textarea>
                ) : (
                  <input
                    type="text"
                    className="w-full p-4 pl-12 bg-gray-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-sky-500 transition duration-300 ease-in-out"
                    placeholder={`Enter your ${field.toLowerCase()}`}
                  />
                )}
                <span className={`absolute left-4 ${field !== 'Message' ? 'top-1/2 transform -translate-y-1/8' : 'top-1/2 transform -translate-y-1/2'} text-gray-400 text-2xl`}>
                  {field === 'Name' && <i className="fas fa-user"></i>}
                  {field === 'Phone No' && <i className="fas fa-mobile"></i>}
                  {field === 'Email' && <i className="fas fa-envelope"></i>}
                  {field === 'Message' && <i className="fas fa-comment"></i>}
                </span>
              </div>
            ))}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-sky-500 to-blue-600 p-4 rounded-lg text-lg font-bold hover:scale-105 hover:shadow-lg transition duration-300 ease-in-out"
            >
              Send Message
            </button>
          </form>
        </div>
      </section>

      {/* About Us Section */}
      <section id="aboutus" className="min-h-screen bg-gray-900 text-white py-16">
        <div className="container mx-auto px-4 md:px-8 text-center">
          <h2 className="text-4xl md:text-5xl font-bold mb-8">About Us</h2>
          <p className="text-lg md:text-xl text-gray-400 mb-16">
            We envision a world where SwitchUp is synonymous with the future of mobility, offering a platform that redefines vehicle ownership through seamless transitions to the latest sustainable technologies, thereby advancing global technological progress.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="text-center bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <img
                src={founder1}
                alt="Founder 1"
                className="rounded-full border-4 border-red-500 shadow-lg mb-6 w-32 md:w-48 h-32 md:h-48 mx-auto"
              />
              <h3 className="text-2xl md:text-2xl font-bold">Sandeep Devakaran</h3>
              <p className="text-gray-400 mb-2">CA, 25+ years of experience in leasing, mobility, automation (EVs) & fintech.</p>
              <p className="text-gray-400">Former CXO at OPC, Prizm Payments & Ola Fleet, and Ola Electric.</p>
            </div>
            <div className="text-center bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
              <img
                src={founder2}
                alt="Founder 2"
                className="rounded-full border-4 border-red-500 shadow-lg mb-6 w-32 md:w-48 h-32 md:h-48 mx-auto"
              />
              <h3 className="text-2xl md:text-2xl font-bold">Slokarth Dash</h3>
              <p className="text-gray-400 mb-2">MBA, ISB & B.Tech, NIT, 10+ years experience in building consumer tech & automotive (EVs).</p>
              <p className="text-gray-400">Former Head of Strategy at Ola Electric & Ola Fleet Tech.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-gray-400 py-12">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 px-4 md:px-8">
          {/* Left Side: Logo and Contact Info */}
          <div className="text-center md:text-left">
            <img src={logo} alt="Logo" className="h-7 mb-4 mx-auto md:ml-4 mx-2" />
            <div className="space-y-3 ">
              <p className="text-lg flex items-center justify-center md:justify-start mx-5">
                <i className="fas fa-map-marker-alt mr-3 text-red-500"></i>
                Hustlehub Tech Park Building,<br />
                PWD Quarters, HSR Layout,<br />
                Bengaluru, Karnataka, 560102
              </p>
              <p className="text-lg flex items-center justify-center md:justify-start">
                <i className="fas fa-envelope mr-3 mx-4 text-red-500"></i>
                hello@SwitchUpev.in
              </p>
              <p className="text-lg flex items-center justify-center md:justify-start">
                <i className="fas fa-phone-alt mr-3 text-red-500 mx-4"></i>
                +91 966 332 50369
              </p>
            </div>
          </div>

          {/* Right Side: Links */}
          <div className="text-center md:text-right m-10">
            <ul className="space-y-2">
              <li>
                <a href="/privacy-policy" className="hover:text-red-500 transition duration-300">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms-of-service" className="hover:text-red-500 transition duration-300">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Horizontal Line */}
        <div className="border-t border-gray-600 mt-8 pt-4">
          <p className="text-center text-sm text-gray-500">
            © Copyright 2024, SwitchUp Technologies Pvt Ltd - All Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  );
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<MainApp />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
    </Routes>
  </Router>
);

export default App;